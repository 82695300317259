import React, { useEffect, useState, useRef } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { HiChevronDown } from "react-icons/hi";
import { PiUploadSimpleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../services/authService";
import { uploadSingleImage } from "../services/commonService";
import Input from "./Input";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignUpForm = ({ handleSignupModalOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const isValidMobileNumber = (phoneNumber) => {
    // Check if it starts with +34 and has exactly 9 digits after that
    const regex = /^\+34[0-9]{9}$/;
    return regex.test(phoneNumber);
  };

  const [mobile, setMobile] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    long: "",
    lati: "",
    role: "guest",
    // mobile: mobile,
    dob: "",
    profileImage: "",
  });
  const [loading, setLoading] = useState(false);
  console.log("useRData", userData);
  const [getPassword, setGetPassword] = useState();
  console.log("getPassword", getPassword);
  const [guestChecked, setGuestChecked] = useState(false);
  const [hostChecked, setHostChecked] = useState(false);
  const [affidavitChecked, setAffidavitChecked] = useState(false);
  const [ifValid, setIfValid] = useState(false);
  const [errors, setErrors] = useState({});
  console.log("🚀 ~ SignUpForm ~ errors:", errors);
  const [errorsEs, setErrorsEs] = useState({});
  console.log("🚀 ~ SignUpForm ~ mobile:", mobile);
  // console.log("errors", errors);
  const isMountLocation = useRef(null);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    // Validate email
    if (!userData.email) {
      newErrors.email = "Email is required";
      newErrorsEs.email = "Se requiere correo electronico ";
      isValid = false;
    }

    if (!isEmail(userData.email) && userData.email) {
      newErrors.emailvalid = "Enter valid email address";
      newErrorsEs.emailvalid = "Introduce un correo electronico valido";
      isValid = false;
    }

    // Validate password
    // if (!userData.password) {
    //   newErrors.password = "Password is required";
    //   newErrorsEs.password = "Se requiere contraseña";
    //   isValid = false;
    // } else
    // if (
    //   !userData.password &&
    //   userData.password.length < 12 &&
    //   !/.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+.*/.test(userData.password)
    // ) {
    //   newErrors.password =
    //     "Password must be 12 alphanumeric characters long and must contain one special character";
    //   newErrorsEs.password =
    //     "La contraseña debe tener 12 caracteres alfanuméricos y debe contener un carácter especial";
    //   isValid = false;
    // }
    if (!getPassword) {
      newErrors.password = "Please enter password";
      newErrorsEs.password = "Por favor ingrese contraseña ";
      isValid = false;
    }
    if (ifValid) {
      newErrors.password = "Please enter valid password";
      newErrorsEs.password = "Por favor ingrese una contraseña válida";
      isValid = false;
    }

    // Validate firstName
    if (!userData.firstName) {
      newErrors.firstName = "First name is required";
      newErrorsEs.firstName = "Se requiere el primer nombre";
      isValid = false;
    }

    // Validate lastName
    if (!userData.lastName) {
      newErrors.lastName = "Last name is required";
      newErrorsEs.lastName = "Se requiere el apellido";
      isValid = false;
    }

    // Validate role
    if (!userData.role) {
      newErrors.role = "Role is required";
      newErrorsEs.role = "Se requiere el rol";
      isValid = false;
    }

    // Validate mobile
    if (!mobile) {
      newErrors.mobile = "Mobile is required";
      newErrorsEs.mobile = "Se requiere el numero de telefono";
      isValid = false;
    }

    // if (!isValidMobileNumber(userData.mobile) && userData.mobile) {
    //   newErrors.mobileValid = "Enter valid mobile number";
    //   newErrorsEs.mobileValid = "Introduce un numero de telefono valido";
    //   isValid = false;
    // }

    // Validate dob
    if (!userData.dob) {
      newErrors.dob = "Date of Birth is required";
      newErrorsEs.dob = "Se requiere fecha de nacimiento";
      isValid = false;
    }

    // Validate profile image
    if (!userData.profileImage) {
      newErrors.profileImage = "Profile Image is required";
      newErrorsEs.profileImage = "Se requiere una imagen de perfil";
      isValid = false;
    }

    if ((!guestChecked || !hostChecked) && !affidavitChecked) {
      newErrors.checkbox = "Please tick mark checkbox.";
      newErrorsEs.checkbox = "Por favor marque la casilla.";
      isValid = false;
    }

    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;
    console.log("name", name, value);
    if (files?.length > 0) {
      const image = files[files.length - 1];

      const imageData = new FormData();
      imageData.append("image", image);
      try {
        const resp = await uploadSingleImage(imageData);

        if (resp?.success) {
          setUserData({
            ...userData,
            [name]: resp?.data,
          });
        } else {
          setUserData({
            ...userData,
            [name]: "",
          });
        }
      } catch (err) {
        console.log("err", err);
      } finally {
      }
    } else if (value) {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  // to get current location
  useEffect(() => {
    if (!isMountLocation.current) {
      navigator.geolocation.getCurrentPosition(success);
      isMountLocation.current = true;
    }
    // eslint-disable-next-line
  }, []);

  function success(position) {
    const lati = position.coords.latitude;
    const long = position.coords.longitude;

    setUserData({
      ...userData,
      lati: lati,
      long: long,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("password", getPassword);

    if (validateForm()) {
      setLoading(true);
      let payload = {
        email: (userData?.email).toLowerCase(),
        password: getPassword,
        role: userData?.role,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        mobile: mobile,
        dob: userData?.dob,
        profileImage: userData?.profileImage,
        location: {
          type: "Point",
          coordinates: [userData?.long, userData?.lati],
        },
        translations: {
          en: {
            firstName: "",
            lastName: "",
          },
        },
      };

      try {
        const resp = await registerUser(payload);

        if (resp?.success) {
          localStorage.setItem("user_email", userData?.email);
          navigate("/verify-otp");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
  };

  // console.log("Error in english: ", errors);
  // console.log("Error in Spanish: ", errorsEs);
  return (
    <>
      <form>
        <div className="container mx-auto w-full rounded-[20px] px-6 py-2 overflow-y-auto h-[500px] md:h-auto">
          <div className="flex justify-center">
            <img src="../images/Loginform/logo.svg" alt="" />
          </div>
          <p className="flex justify-center font-Comfortaa-Bold text-2xl text-[#111] mt-2 mb-1">
            {t("homePage.welcome")}
          </p>
          <span className="flex justify-center text-[16px] font-Glacial text-[#757575]">
            {t("homePage.createAccount")}
          </span>

          <div className="pt-2 md:grid md:grid-rows-4 md:grid-flow-col gap-x-5">
            {/* first name */}
            <div>
              <div className="relative flex items-center py-2">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <HiOutlineUser />
                </div>
                <input
                  name="firstName"
                  className="w-full mx-auto focus:ring-2 focus:border-primary focus:ring-primary h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] xl:pe-20    font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB] "
                  type="text"
                  placeholder={t("homePage.enterFirstname")}
                  onChange={handleInputChange}
                />
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.firstName && userData?.firstName === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.firstName}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.firstName && userData?.firstName === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.firstName}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* last name */}
            <div>
              <div className="relative flex items-center py-2">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <HiOutlineUser />
                </div>
                <input
                  name="lastName"
                  className="w-full mx-auto h-[70px] focus:ring-2 focus:border-primary focus:ring-primary border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] xl:pe-20    font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB] "
                  type="text"
                  placeholder={t("homePage.enterLastname")}
                  onChange={handleInputChange}
                />
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.lastName && userData?.lastName === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.lastName}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.lastName && userData?.lastName === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.lastName}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* mobile */}
            <div>
              <div className=" relative flex items-center py-2">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <img src="../images/Signupform/call.svg" alt="" />
                </div>
                {/* <input
                  className={`w-full mx-auto focus:ring-2 focus:border-primary focus:ring-primary h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] pe-4 font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB]
               `}
                  name="mobile"
                  type="tel"
                  maxLength={12}
                  pattern="[0-9]*"
                  placeholder={t("homePage.enterMobile")}
                  onChange={handleInputChange}
                /> */}

                <PhoneInput
                  country={"es"}
                  placeholder="Enter Mobile Number"
                  value={mobile}
                  // onChange={handleInputChange}
                  onChange={(phone) => setMobile(phone)}
                  inputProps={{
                    style: {
                      width: "100%",
                      height: "70px",
                      borderRadius: "10px",
                      fontSize: "18px",
                    },
                  }}
                  className="outline-none text-lg"
                />
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.mobile && mobile === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.mobile}
                    </div>
                  )}
                  {errors.mobileValid && (
                    <div className="text-red-300 ml-10 font-Glacial">
                      {errors.mobileValid}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.mobile && mobile === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.mobile}
                    </div>
                  )}
                  {errorsEs.mobileValid && (
                    <div className="text-red-300 ml-10 font-Glacial">
                      {errorsEs.mobileValid}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* dob */}
            <div className="">
              <div className="relative flex items-center py-2">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <img src="../images/Signupform/cake.svg" alt="" />
                </div>
                <input
                  name="dob"
                  className="w-full focus:ring-2 text-[#ABABAB] focus:border-primary focus:ring-primary mx-auto h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] xl:pe-20 font-Glacial sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB] "
                  type="date"
                  placeholder="Enter your DOB *"
                  onChange={handleInputChange}
                />
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.dob && userData?.dob === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.dob}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.dob && userData?.dob === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.dob}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* email */}
            <div>
              <div className="relative flex items-center py-2 ">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <img src="../images/Loginform/maile.svg" alt="" />
                </div>
                <input
                  name="email"
                  className="w-full mx-auto focus:ring-2 focus:border-primary focus:ring-primary h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] pe-4 font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB] "
                  type="email"
                  placeholder={t("homePage.enterEmail")}
                  onChange={handleInputChange}
                />
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.email && userData?.email === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.email}
                    </div>
                  )}
                  {errors.emailvalid && (
                    <div className="text-red-300 ml-10 font-Glacial">
                      {errors.emailvalid}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.email && userData?.email === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.email}
                    </div>
                  )}
                  {errorsEs.emailvalid && (
                    <div className="text-red-300 ml-10 font-Glacial">
                      {errorsEs.emailvalid}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* password */}
            <div>
              <div className="relative flex items-center py-2 ">
                <div className="absolute z-50 text-2xl text-primary p-3 -mt-5 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <img src="../images/Signupform/lock.svg" alt="" />
                </div>
                <Input
                  id="password"
                  name="password"
                  className="w-full mx-auto focus:ring-2 focus:border-primary focus:ring-primary h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] pe-4 font-Glacial  sm:text-[20px] text-[16px]  placeholder:text-[#ABABAB]"
                  type="password"
                  placeholder={t("homePage.enterPassword")}
                  setGetPassword={setGetPassword}
                  setIfValid={setIfValid}
                  passwordIndicatorVisible={true}
                />
              </div>

              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.password && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errors.password}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.password && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.password}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* image */}
            <div>
              <div className="relative flex items-center py-2">
                <div className="absolute text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                  <img src="../images/Dishphotos/image.svg" alt="" />
                </div>
                <div className="flex w-full mx-auto h-[70px]  items-center justify-center bg-grey-lighter border rounded-lg">
                  <label className="w-full h-[70px]  flex flex-col items-start transition delay-150  ps-[84px] xl:pe-20 cursor-pointer bg-transparent">
                    {userData?.profileImage ? (
                      <div className="flex justify-start items-center gap-2 mt-2">
                        <img
                          className=" object-contain"
                          src={userData?.profileImage}
                          alt="profile"
                          width={30}
                          height={30}
                        />
                        <span className="my-5  leading-normal text-[#ABABAB] font-Glacial  text-[16px] ">
                          Profile Image
                        </span>
                      </div>
                    ) : (
                      <span className="my-5  leading-normal text-[#ABABAB] font-Glacial  sm:text-[20px] text-[16px] ">
                        {t("homePage.uploadImg")}
                      </span>
                    )}
                    <input
                      name="profileImage"
                      type="file"
                      className="hidden border border-gray-300 rounded-lg focus:ring-2 focus:border-primary focus:ring-primary "
                      onChange={handleInputChange}
                    />
                  </label>
                  <PiUploadSimpleLight className="absolute right-[24px] text-2xl text-primary cursor-pointer" />
                </div>
              </div>
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.profileImage && userData?.profileImage === "" && (
                    <div className="text-red-300  ml-10 font-Glacial text-lg">
                      {errors.profileImage}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.profileImage && userData?.profileImage === "" && (
                    <div className="text-red-300 ml-10 font-Glacial text-lg">
                      {errorsEs.profileImage}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* role */}
            <div className="relative flex items-center -mt-7 py-2  ">
              <div className="host absolute z-10 text-2xl text-primary p-3 flex items-center justify-center border-r only: w-[70px] h-[70px] bg-transparent">
                {userData?.role === "guest" ? (
                  <img src="../images/Signupform/guest.svg" alt="" />
                ) : (
                  <img
                    className="p-1"
                    src="../images/Signupform/host.svg"
                    alt=""
                  />
                )}{" "}
              </div>
              <fieldset className="w-full">
                <div className="guestselection relative bg-transparent ">
                  <label for="frm-whatever" className="sr-only">
                    My field
                  </label>
                  <select
                    className="SignUpFormearance-none focus:ring-2 focus:border-primary focus:ring-primary   w-full h-[70px] border border-gray-300 rounded-lg outline-none transition delay-150  ps-[84px] xl:pe-20 cursor-pointer text-primary font-Glacial sm:text-[20px] text-[16px] bg-transparent"
                    name="role"
                    id="frm-whatever"
                    value={userData?.role}
                    onChange={handleInputChange}
                    style={{
                      "&:hover": { backgroundColor: "#4a90e2", color: "#fff" },
                    }}
                  >
                    <option value="guest">{t("homePage.guest")}</option>
                    <option value="host">{t("homePage.host")}</option>
                  </select>
                </div>
              </fieldset>
              <HiChevronDown className="absolute right-[12px] text-2xl text-primary cursor-pointer" />
            </div>
          </div>
          <div className="font-Glacial text-gray-500 text-lg xl:ml-10">
            {userData?.role === "guest" ? (
              <>
                <input
                  type="checkbox"
                  id="guest"
                  className="checked:bg-primary hover:checked:bg-primary checked:ring-0 ring-0"
                  checked={guestChecked}
                  onChange={() => setGuestChecked(!guestChecked)}
                />
                {lang === "en" || lang === "en-US" ? (
                  <>
                    <label for="guest" className="ml-2">
                      I have read and agree to the{" "}
                      <Link
                        to="/terms-condition-guest"
                        className="text-primary underline"
                      >
                        Terms and conditions for guest.
                      </Link>
                    </label>
                  </>
                ) : (
                  <>
                    <label for="guest" className="ml-2">
                      he leído y estoy de acuerdo con{" "}
                      <Link
                        to="/terms-condition-guest"
                        className="text-primary underline"
                      >
                        Términos y condiciones para la invitada.
                      </Link>
                    </label>
                  </>
                )}

                <br />
              </>
            ) : (
              <>
                <input
                  type="checkbox"
                  id="host"
                  className="checked:bg-primary hover:checked:bg-primary checked:ring-0 ring-0"
                  checked={hostChecked}
                  onChange={() => setHostChecked(!hostChecked)}
                />
                {lang === "en" || lang === "en-US" ? (
                  <>
                    <label for="host" className="ml-2">
                      I have read and agree to the{" "}
                      <Link
                        to="/terms-condition-host"
                        className="text-primary underline"
                      >
                        Terms and conditions for host.
                      </Link>
                    </label>
                  </>
                ) : (
                  <>
                    <label for="guest" className="ml-2">
                      he leído y estoy de acuerdo con{" "}
                      <Link
                        to="/terms-condition-guest"
                        className="text-primary underline"
                      >
                        Términos y condiciones para la anfitrión.
                      </Link>
                    </label>
                  </>
                )}

                <br />
              </>
            )}
            <input
              type="checkbox"
              id="affidavit"
              className="checked:bg-primary hover:checked:bg-primary checked:ring-0 ring-0"
              checked={affidavitChecked}
              onChange={() => setAffidavitChecked(!affidavitChecked)}
            />
            {lang === "en" || lang === "en-US" ? (
              <>
                <label for="affidavit" className="ml-2">
                  I have read{" "}
                  <Link to="/affidavit" className="text-primary underline">
                    affidavit.
                  </Link>
                </label>
              </>
            ) : (
              <>
                <label for="affidavit" className="ml-2">
                  he leído{" "}
                  <Link to="/affidavit" className="text-primary underline">
                    declaración responsable.{" "}
                  </Link>
                </label>
              </>
            )}

            <br />
          </div>
          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.checkbox && (
                <div className="text-red-300 ml-10 font-Glacial text-lg">
                  {errors.checkbox}
                </div>
              )}
            </>
          ) : (
            <>
              {errorsEs.checkbox && (
                <div className="text-red-300 ml-10 font-Glacial text-lg">
                  {errorsEs.checkbox}
                </div>
              )}
            </>
          )}
          <div className="flex justify-center pt-[10px] ">
            {loading ? (
              <>
                <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[396px] py-2  flex mx-auto items-center justify-center ">
                  <Loader size={38} />
                </div>
              </>
            ) : (
              <button
                type="submit"
                className="bg-primary text-white py-[18px] w-[396px]  mx-auto  font-Comfortaa-Medium text-[16px] rounded-full"
                onClick={handleSubmit}
              >
                {t("signUp")}
              </button>
            )}
          </div>

          <div className="text-[#ABABAB] font-Glacial Indifference text-[16px] flex items-center justify-center  mt-3">
            {t("homePage.alreadyHaveAccount")}
            <span
              onClick={handleSignupModalOpen}
              className="cursor-pointer text-primary"
            >
              &nbsp;{t("login")}.
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignUpForm;
